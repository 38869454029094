export default class Category {
    constructor(name, origin_id) {
        this._name = name;
        this._origin_id = origin_id;
    }

    get name() {
        return this._name;
    }

    get origin_id() {
        return this._origin_id;
    }
}