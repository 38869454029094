<script>
export default {
  name: "Breadcrumbs",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<template>
  <div class="breadcrumbs">
    <template v-for="item in items">
      <span v-if="item.to"><router-link :to="item.to" v-html="item.title"></router-link></span>
      <span v-else v-html="item.title"></span>
      <span class="separator">/</span>
    </template>
  </div>
</template>

<style scoped lang="scss">
.breadcrumbs {
  padding: 0 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .separator {
    margin: 0 5px;
  }

  .separator:last-child {
    display: none;
  }

  span {
    color: var(--neutral-800);
  }

  a {
    color: var(--neutral-700);
  }
}
</style>