import AppLocaleInfo from "../objects/AppLocaleInfo";
import Store from "../objects/Store";
import App from "../objects/App";
import Country from "../objects/Country";
import {httpRequest} from "../index";
import Category from "../objects/Category";
import {percentBetween, percentDiff} from "../../utils/utils";

export default {
    getList: function () {
        let url = process.env.VUE_APP_API_URL + 'api/market-research/user-list';
        return new Promise(async (resolve, reject) => {
            await httpRequest('GET', url)
                .then(response => {
                    let result = [];

                    response.forEach((item) => {
                        let localeInfo = new AppLocaleInfo();
                        localeInfo.name = item.app_data.name;
                        localeInfo.logo = item.app_data.logo;
                        item.app = new App(
                            item.app_id,
                            item.app_data.origin_id,
                            new Store(null, item.app_data.store)
                        );
                        item.app.locale_info = localeInfo;

                        result.push(item);
                    });

                    resolve(result);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    },
    deleteMarketResearch(id) {
        let url = process.env.VUE_APP_API_URL + 'api/market-research/delete?id=' + id;
        return new Promise((resolve, reject) => {
            httpRequest('GET', url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    },
    makeMarketResearch: function (appId, competitorsIds, dateFrom, dateTo) {
        let url = process.env.VUE_APP_API_URL + 'api/market-research/make';
        url += '?appId=' + appId;
        competitorsIds.forEach(competitor => {
            if (competitor === appId) {
                return;
            }
            url += '&competitors[]=' + competitor;
        });
        url += '&dateFrom=' + dateFrom;
        url += '&dateTo=' + dateTo;

        return new Promise((resolve, reject) => {
            httpRequest('GET', url)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    },
    getMarketResearchData: function (researchId) {
        let url = process.env.VUE_APP_API_URL + 'api/market-research/get?id=' + researchId;

        return new Promise((resolve, reject) => {
            httpRequest('GET', url)
                .then(response => {
                    let apps = {};
                    response.data.apps_data.forEach(app => {
                        apps[app.id] = new App(app.id, app.origin_id, new Store(null, app.store));
                        let localeInfo = new AppLocaleInfo();
                        localeInfo.name = app.name;
                        localeInfo.logo = app.logo;
                        apps[app.id].locale_info = localeInfo;
                    });

                    let countriesIndex = {};
                    let byAppsData = [];
                    for (const [key, value] of Object.entries(response.data.by_apps)) {
                        byAppsData.push({...value, id: key});
                    }

                    let mainAppData = response.data.by_apps[response.research.app_data.id];

                    let result = {
                        iap: {
                            avg_from: response.data.by_research.avg_iap_from,
                            avg_to: response.data.by_research.avg_iap_to,
                            max_to: response.data.by_research.max_iap_to,
                            max_from: response.data.by_research.max_iap_from,
                            min_to: response.data.by_research.min_iap_to,
                            min_from: response.data.by_research.min_iap_from,
                            app_avg_from: mainAppData?.avg?.avg_iap_from,
                            app_avg_to: mainAppData?.avg?.avg_iap_to,
                            app_avg_from_percent: percentDiff(response.data.by_research.avg_iap_from, mainAppData?.avg?.avg_iap_from).toFixed(1) * -1,
                            app_avg_to_percent: percentDiff(response.data.by_research.avg_iap_to, mainAppData?.avg?.avg_iap_to).toFixed(1) * -1,
                        },
                        updates: {
                            avg_release_date: response.data.by_research.avg_release_date,
                            min_age_days: response.data.by_research.min_age_days,
                            max_age_days: response.data.by_research.max_age_days,
                            avg_age_days: response.data.by_research.avg_age_days,
                            app_age_days: mainAppData?.stat?.age_days,
                            app_age_days_percent: percentDiff(response.data.by_research.avg_age_days, mainAppData?.stat?.age_days).toFixed(1),
                            app_release_date: mainAppData?.stat?.release_date,
                        },
                    };

                    result.top_apps_by_revenue = byAppsData.sort((a, b) => b.totals.sum_revenue - a.totals.sum_revenue).slice(0, 10).map(appData => {
                        return {
                            app: apps[appData.id],
                            revenue: appData.totals.sum_revenue,
                        }
                    });
                    result.top_apps_by_installs = byAppsData.sort((a, b) => b.totals.sum_installs - a.totals.sum_installs).slice(0, 10).map(appData => {
                        return {
                            app: apps[appData.id],
                            installs: appData.totals.sum_installs,
                        }
                    });

                    let minUpdateFreq = 90;
                    let maxUpdateFreq = 0;
                    byAppsData.forEach(appData => {
                        if (!appData.stat.update_freq_d) {
                            return;
                        }
                        if (appData.stat.update_freq_d > maxUpdateFreq) {
                            maxUpdateFreq = appData.stat.update_freq_d;
                        }
                        if (appData.stat.update_freq_d < minUpdateFreq) {
                            minUpdateFreq = appData.stat.update_freq_d;
                        }
                    });
                    maxUpdateFreq = Math.max(maxUpdateFreq * 0.80, 90, minUpdateFreq + 1).toFixed();
                    minUpdateFreq = Math.min(Math.max(minUpdateFreq.toFixed(), 7), 30);

                    result.top_apps_by_update_frequency = byAppsData.sort((a, b) => a.stat.update_freq_d - b.stat.update_freq_d).slice(0, 10).map(appData => {
                        return {
                            app: apps[appData.id],
                            update_frequency_d: appData.stat.update_freq_d,
                            avg_rating: appData.avg.avg_rating,
                            sum_votes: appData.avg.sum_votes,
                            percent_update_freq_between_min_max: maxUpdateFreq ? 100 - percentBetween(appData.stat.update_freq_d, minUpdateFreq, maxUpdateFreq).toFixed() : 0,
                        }
                    });

                    result.top_apps_with_highest_iap = byAppsData.filter((item) => item.avg.avg_iap_to).sort((a, b) => b.avg.avg_iap_to - a.avg.avg_iap_to).slice(0, 10).map(appData => {
                        return {
                            app: apps[appData.id],
                            avg_iap_to: appData.avg.avg_iap_to,
                            avg_iap_from: appData.avg.avg_iap_from,
                        }
                    });
                    result.top_apps_with_lowest_iap = byAppsData.filter((item) => item.avg.avg_iap_to).sort((a, b) => a.avg.avg_iap_to - b.avg.avg_iap_to).slice(0, 10).map(appData => {
                        return {
                            app: apps[appData.id],
                            avg_iap_to: appData.avg.avg_iap_to,
                            avg_iap_from: appData.avg.avg_iap_from,
                        }
                    });

                    let maxAgeDays = 0;
                    let minAgeDays = 0;
                    byAppsData.forEach(appData => {
                        if (appData.stat.age_days > maxAgeDays) {
                            maxAgeDays = appData.stat.age_days;
                        }
                    });
                    maxAgeDays = Math.min(maxAgeDays * 0.8, 365 * 10).toFixed();
                    minAgeDays = Math.min((maxAgeDays * 0.2).toFixed(), 30 * 3);

                    result.top_youngest_apps = byAppsData.sort((a, b) => a.stat.age_days - b.stat.age_days).slice(0, 10).map(appData => {
                        return {
                            app: apps[appData.id],
                            age_days: appData.stat.age_days,
                            avg_rating: appData.avg.avg_rating,
                            sum_votes: appData.avg.sum_votes,
                            sum_installs: appData.totals.sum_installs,
                            sum_revenue: appData.totals.sum_revenue,
                            release_date: appData.stat.release_date,
                            percent_age_days_between_min_max: maxAgeDays ? percentBetween(appData.stat.age_days, minAgeDays, maxAgeDays).toFixed() : 0,
                        }
                    });
                    result.top_oldest_apps = byAppsData.sort((a, b) => b.stat.age_days - a.stat.age_days).slice(0, 10).map(appData => {
                        return {
                            app: apps[appData.id],
                            age_days: appData.stat.age_days,
                            avg_rating: appData.avg.avg_rating,
                            sum_votes: appData.avg.sum_votes,
                            sum_installs: appData.totals.sum_installs,
                            sum_revenue: appData.totals.sum_revenue,
                            release_date: appData.stat.release_date,
                            percent_age_days_between_min_max: maxAgeDays ? percentBetween(appData.stat.age_days, minAgeDays, maxAgeDays).toFixed() : 0,
                        }
                    });

                    let byCountriesData = [];
                    for (const [key, value] of Object.entries(response.data.by_country)) {
                        if (!countriesIndex[key]) {
                            countriesIndex[key] = new Country(value.country.name, value.country.code);
                        }
                        byCountriesData.push({...value, countryObj: countriesIndex[key]});
                    }

                    let sumOfAvgVotes = 0;
                    let sumOfInstalls = 0;
                    let sumOfRevenue = 0;
                    byCountriesData.forEach(countryData => {
                        sumOfAvgVotes += countryData.avg.avg_votes ?? 0;
                        sumOfInstalls += countryData.totals.sum_installs ?? 0;
                        sumOfRevenue += countryData.totals.sum_revenue ?? 0;
                    });
                    result.top_countries_by_votes = byCountriesData.sort((a, b) => b.avg.avg_votes - a.avg.avg_votes).slice(0, 10).map(countryData => {
                        let byAppDatum = countryData.apps[response.research.app_data.id];
                        return {
                            country: countryData.countryObj,
                            avg_votes: (0 + countryData.avg.avg_votes).toFixed(),
                            avg_rating: countryData.avg.avg_rating,
                            avg_votes_of_total_percent: sumOfAvgVotes ? (countryData.avg.avg_votes / sumOfAvgVotes * 100).toFixed(3) : 0,
                            app_rating: {
                                value: byAppDatum?.avg_rating || 0,
                                dynamic: byAppDatum?.avg_rating ? (byAppDatum?.avg_rating - countryData.avg.avg_rating).toFixed(1) : null,
                            },
                            app_votes: {
                                value: byAppDatum?.avg_votes,
                                dynamic_percent: percentDiff(countryData.avg.avg_votes, byAppDatum?.avg_votes).toFixed(1),
                            }
                        }
                    });


                    let maxInstalls = 0;
                    let minInstalls = 100;
                    let maxRevenue = 0;
                    let minRevenue = 0;
                    byCountriesData.forEach(countryData => {
                        if (countryData.totals.sum_installs) {
                            if (countryData.totals.sum_installs > maxInstalls) {
                                maxInstalls = countryData.totals.sum_installs;
                            }
                            if (countryData.totals.sum_installs < minInstalls) {
                                minInstalls = countryData.totals.sum_installs;
                            }
                        }
                        if (countryData.totals.sum_revenue) {
                            if (countryData.totals.sum_revenue > maxRevenue) {
                                maxRevenue = countryData.totals.sum_revenue;
                            }
                            if (countryData.totals.sum_revenue < minRevenue) {
                                minRevenue = countryData.totals.sum_revenue;
                            }
                        }
                    });
                    maxInstalls = Math.max(maxInstalls * 0.90, minInstalls + 1).toFixed();
                    minInstalls = Math.max(minInstalls, 100, maxInstalls * 0.10).toFixed();
                    maxRevenue = Math.max(maxRevenue * 0.90, minRevenue + 1).toFixed();
                    minRevenue = Math.max(minRevenue, 100, maxRevenue * 0.10).toFixed();

                    result.countries_installs_revenue_share = byCountriesData.map(countryData => {
                        let byCountryAppsInstallsRevenue = [];
                        for (const [appId, appData] of Object.entries(countryData.apps)) {
                            if (appData.sum_installs || appData.sum_revenue) {
                                byCountryAppsInstallsRevenue.push({
                                    app: apps[appId],
                                    installs: appData.sum_installs,
                                    revenue: appData.sum_revenue,
                                });
                            }
                        }

                        return {
                            country: countryData.countryObj,
                            installs: countryData.totals.sum_installs,
                            revenue: countryData.totals.sum_revenue,
                            installs_of_total_percent: sumOfInstalls ? (countryData.totals.sum_installs / sumOfInstalls * 100).toFixed(3) : 0,
                            revenue_of_total_percent: sumOfRevenue ? (countryData.totals.sum_revenue / sumOfRevenue).toFixed(3) * 100 : 0,
                            installs_percent_between_min_max: maxInstalls ? percentBetween(countryData.totals.sum_installs, minInstalls, maxInstalls).toFixed() : 0,
                            revenue_percent_between_min_max: maxRevenue ? percentBetween(countryData.totals.sum_revenue, minRevenue, maxRevenue).toFixed() : 0,
                            byApps: byCountryAppsInstallsRevenue
                        }
                    }).filter(item => item.installs > 0 || item.revenue > 0);

                    maxInstalls = 0;
                    minInstalls = 100;
                    maxRevenue = 0;
                    minRevenue = 0;
                    let totalRevenue = 0;
                    let totalInstalls = 0;
                    byAppsData.forEach(appData => {
                        if (appData.totals.sum_installs) {
                            if (appData.totals.sum_installs > maxInstalls) {
                                maxInstalls = appData.totals.sum_installs;
                            }
                            if (appData.totals.sum_installs < minInstalls) {
                                minInstalls = appData.totals.sum_installs;
                            }
                            totalInstalls += appData.totals.sum_installs;
                        }
                        if (appData.totals.sum_revenue) {
                            if (appData.totals.sum_revenue > maxRevenue) {
                                maxRevenue = appData.totals.sum_revenue;
                            }
                            if (appData.totals.sum_revenue < minRevenue) {
                                minRevenue = appData.totals.sum_revenue;
                            }
                            totalRevenue += appData.totals.sum_revenue;
                        }
                    });

                    result.apps_installs_revenue_share = byAppsData.map(appData => {
                        return {
                            app: apps[appData.id],
                            installs: appData.totals.sum_installs,
                            revenue: appData.totals.sum_revenue,
                            installs_of_total_percent: totalInstalls ? (appData.totals.sum_installs / totalInstalls * 100).toFixed(3) : 0,
                            revenue_of_total_percent: totalRevenue ? (appData.totals.sum_revenue / totalRevenue).toFixed(3) * 100 : 0,
                            installs_percent_between_min_max: maxInstalls ? percentBetween(appData.totals.sum_installs, minInstalls, maxInstalls).toFixed() : 0,
                            revenue_percent_between_min_max: maxRevenue ? percentBetween(appData.totals.sum_revenue, minRevenue, maxRevenue).toFixed() : 0,
                        }
                    }).filter(item => item.installs > 0 || item.revenue > 0);

                    let minAvgRating = 1;
                    let maxAvgRating = 4.9;
                    let minSumVotes = 100;
                    let maxSumVotes = minSumVotes + 1;
                    let sumVotes = 0;
                    byCountriesData.forEach(countryData => {
                        if (countryData.avg.avg_rating) {
                            if (countryData.avg.avg_rating > maxAvgRating) {
                                maxAvgRating = countryData.avg.avg_rating;
                            }
                            if (countryData.avg.avg_rating < minAvgRating) {
                                minAvgRating = countryData.avg.avg_rating;
                            }
                        }
                        if (countryData.totals.sum_votes) {
                            if (countryData.totals.sum_votes > maxSumVotes) {
                                maxSumVotes = countryData.totals.sum_votes;
                            }
                            if (countryData.totals.sum_votes < minSumVotes) {
                                minSumVotes = countryData.totals.sum_votes;
                            }
                            sumVotes += countryData.totals.sum_votes;
                        }
                    });
                    minAvgRating = minAvgRating.toFixed(1);
                    maxAvgRating = maxAvgRating.toFixed(1);
                    minSumVotes = Math.max(minSumVotes, 100, maxSumVotes * 0.1).toFixed();
                    maxSumVotes = Number(maxSumVotes * 0.9).toFixed();

                    result.countries_votes_share = byCountriesData.map(countryData => {
                        let byCountryAppsVotes = [];
                        for (const [appId, appData] of Object.entries(countryData.apps)) {
                            if (appData.avg_rating || appData.sum_votes) {
                                byCountryAppsVotes.push({
                                    app: apps[appId],
                                    avg_rating: appData.avg_rating,
                                    sum_votes: appData.sum_votes,
                                });
                            }
                        }

                        return {
                            country: countryData.countryObj,
                            avg_rating: countryData.avg.avg_rating,
                            sum_votes: countryData.totals.sum_votes,
                            avg_rating_percent_between_min_max: maxAvgRating ? percentBetween(countryData.avg.avg_rating, minAvgRating, maxAvgRating).toFixed() : 0,
                            sum_votes_percent_between_min_max: maxSumVotes ? percentBetween(countryData.totals.sum_votes, minSumVotes, maxSumVotes).toFixed() : 0,
                            sum_votes_of_total_percent: sumVotes ? (countryData.totals.sum_votes / sumVotes * 100).toFixed(3) : 0,
                            byApps: byCountryAppsVotes
                        }
                    });

                    let byCountyDateData = {};
                    let byAppDateData = {};
                    for (const [date, dateData] of Object.entries(response.data.by_date)) {
                        for (const [countryCode, countryData] of Object.entries(dateData.countries)) {
                            if (!byCountyDateData[countryCode]) {
                                byCountyDateData[countryCode] = {
                                    country: countriesIndex[countryCode],
                                    history: {}
                                };
                            }
                            byCountyDateData[countryCode].history[date] = countryData;
                        }
                        for (const [appId, appData] of Object.entries(dateData.apps)) {
                            if (!byAppDateData[appId]) {
                                byAppDateData[appId] = {
                                    app: apps[appId],
                                    history: {}
                                };
                            }
                            byAppDateData[appId].history[date] = appData;
                        }
                    }

                    result.history_by_countries = Object.values(byCountyDateData);
                    result.history_by_apps = Object.values(byAppDateData);

                    let tops = [
                        'top_50',
                        'top_100',
                    ];
                    let types = [
                        'top-grossing',
                        'top-free',
                    ];
                    result.charts_data = {};
                    result.categories = [];
                    response.data.by_chart.forEach(chartData => {
                        let category = new Category(chartData.category.name, chartData.category.origin_id);
                        category.countries_count = 0;
                        result.categories.push(category);
                        tops.forEach(top => {
                            types.forEach(type => {
                                if (!result.charts_data[chartData.category.origin_id])
                                    result.charts_data[chartData.category.origin_id] = {};
                                if (!result.charts_data[chartData.category.origin_id][type])
                                    result.charts_data[chartData.category.origin_id][type] = {};
                                if (!result.charts_data[chartData.category.origin_id][type][top])
                                    result.charts_data[chartData.category.origin_id][type][top] = [];

                                if (chartData.clusters[type]) {
                                    chartData.clusters[type].forEach(grossingData => {
                                        if (!countriesIndex[grossingData.country.code]) {
                                            countriesIndex[grossingData.country.code] = new Country(grossingData.country.name, grossingData.country.code);
                                        }

                                        if (grossingData.data[top]) {
                                            category.countries_count += 1;
                                            result.charts_data[chartData.category.origin_id][type][top].push({
                                                country: countriesIndex[grossingData.country.code],
                                                avg_pos: grossingData.data[top].avg_pos,
                                                competitors_in_top: grossingData.data[top].competitors,
                                                app_pos: {
                                                    value: grossingData.data[top].main_app_avg_pos,
                                                    dynamic: grossingData.data[top].main_app_avg_pos ? Number(grossingData.data[top].avg_pos - grossingData.data[top].main_app_avg_pos).toFixed() : null,
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        });
                    });
                    result.categories = result.categories.sort((a, b) => b.countries_count - a.countries_count);

                    result.research = response.research;
                    result.research.app = apps[result.research.app_data.id];
                    result.research.avg_update_freq_d = byAppsData.reduce((acc, item) => acc + item.stat.update_freq_d, 0) / byAppsData.length;

                    resolve(result);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}